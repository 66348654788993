import React, { useEffect, Fragment } from 'react';

const ColdPlunge = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <Fragment>
            <main id="main">

                <section class="alt-services section-bg section-description">
                    <div class="container" data-aos="fade-up">

                        <div class="row justify-content-around">
                            <div class="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Cold Plunge</h3>
                                <h4>Embrace the cold. Reap the rewards.</h4>

                                <p className="mt-4">Step into our cold plunge for a guided, transformative experience. The benefits? Reduced inflammation, improved recovery, and a sharper mind. Cold immersion stimulates your nervous system and builds resilience, turning discomfort into growth. Our setup is inspired by the coolest recovery practices in the world—because this is more than a plunge; it’s a mindset. Pair it with red light therapy or post-workout recovery to amplify your results.</p>
                            </div>

                            <div class="col-lg-6 img-bg" style={{ backgroundImage: `url("/assets/img/home/cold_plunge.jpeg")` }} data-aos="zoom-in" data-aos-delay="100"></div>
                        </div>

                    </div>
                </section>
            </main>            
        </Fragment>
    )
}

export default ColdPlunge;