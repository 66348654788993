import React, { useEffect, Fragment } from 'react';

const Lagree = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <Fragment>
            <main id="main">

                <section class="alt-services section-bg section-description">
                    <div class="container" data-aos="fade-up">

                        <div class="row justify-content-around">
                            <div class="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Lagree</h3>
                                <h4>The Workout That Changes Everything.</h4>

                                <p className="mt-4">Lagree isn’t just a fitness trend—it’s a revolution. Combining low-impact, high-intensity movements on a Megaformer, Lagree delivers strength, endurance, and balance like no other workout. Expect slow, controlled movements that fire up every muscle and build a strong, lean body. It’s tough, it’s effective, and it’s worth every second.</p>
                            </div>

                            <div class="col-lg-6 img-bg" style={{ backgroundImage: `url("/assets/img/home/lagree.jpg")` }} data-aos="zoom-in" data-aos-delay="100"></div>
                        </div>

                    </div>
                </section>
            </main>            
        </Fragment>
    )
}

export default Lagree;