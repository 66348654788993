import React, { useEffect, Fragment } from 'react';

const TheBarStudio = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <Fragment>
            <main id="main">

                <section class="alt-services about section-bg section-description">
                    <div class="container" data-aos="fade-up">

                        <div class="row justify-content-around">
                            <div class="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>The Bar Studio</h3>
                                <h4>Movement Meets Self-Connection.</h4>

                                <p className="mt-4">TBS is a mind-body experience designed to help you reconnect with yourself. Set in an intimate, beautifully designed studio, every class encourages you tomove with intention.</p>

                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Barre: Inspired by the elegance of dance and the precision of Pilates, our Barre classes are rooted in small, controlled movements that target and tone every muscle group. Build strength, improve posture, and leave feeling like your best self.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Sculpt: An energetic, full-body workout that blends resistance training, isometric holds, and dynamic movements for a burn that’s as rewarding as it is intense. You’ll sweat, tone, and uncover a whole new level of strength.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Flow: A transformative, rhythm-based journey. Combining breathwork, dynamic stretches, and functional movements, this class isn’t just a flow—it’s a reset for your body and mind.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Cardio Dance: Channel your inner performer with a high-energy class designed to make you sweat and smile. Inspired by the signature moves of Tracey Anderson, this class is about fun, rhythm, and a killer cardio burn.</span></li>
                                </ul>
                            </div>

                            <div class="col-lg-6 img-bg" style={{ backgroundImage: `url("/assets/img/home/the_bar_studio.jpeg")` }} data-aos="zoom-in" data-aos-delay="100"></div>
                        </div>

                    </div>
                </section>
            </main>            
        </Fragment>
    )
}

export default TheBarStudio;