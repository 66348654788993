import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Inicio from './views/Inicio';
import Turf from './views/Turf';
import TheBarStudio from './views/TheBarStudio';
import Lagree from './views/Lagree';
import RedRoom from './views/RedRoom';
import ColdPlunge from './views/ColdPlunge';
import OpenGym from './views/OpenGym';

import Alert from './components/layout/Alert';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Extras from './components/layout/Extras';
import NotFoundPage from './components/routing/NotFoundPage';

//Redux
import store from './store';

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Header></Header>
					<Alert></Alert>
					<Routes>
						<Route exact path="/" element={<Inicio />} />
						<Route exact path="/turf" element={<Turf />} />
						<Route exact path="/the_bar_studio" element={<TheBarStudio />} />
						<Route exact path="/lagree" element={<Lagree />} />
						<Route exact path="/red_room" element={<RedRoom />} />
						<Route exact path="/cold_plunge" element={<ColdPlunge />} />
						<Route exact path="/open_gym" element={<OpenGym />} />

						{/* Cuando no encuentra nada*/}
						<Route path="*" element={<NotFoundPage />}/>
					</Routes>
					<Footer></Footer>
					<Extras></Extras>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
