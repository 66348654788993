import React, { useEffect, Fragment } from 'react';

const RedRoom = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <Fragment>
            <main id="main">

                <section class="alt-services section-bg section-description">
                    <div class="container" data-aos="fade-up">

                        <div class="row justify-content-around">
                            <div class="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Red Room: Infrared & Red Light Therapy</h3>
                                <h4>Glow from the inside out.</h4>

                                <p className="mt-4">Our Red Room is your sanctuary for healing and rejuvenation. Infrared therapy penetrates deep into your muscles, boosting circulation, improving recovery, and easing joint pain. Red light stimulates collagen production and promotes cellular repair for healthier skin and a stronger you. Pro Tip: Combine it with cold plunge sessions for a powerhouse recovery ritual, or use it after a workout to enhance your results. Wellness isn’t just an option—it’s a lifestyle, and this is your time to shine.</p>
                            </div>

                            <div class="col-lg-6 img-bg" style={{ backgroundImage: `url("/assets/img/home/red_room.jpg")` }} data-aos="zoom-in" data-aos-delay="100"></div>
                        </div>

                    </div>
                </section>
            </main>            
        </Fragment>
    )
}

export default RedRoom;