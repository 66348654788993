import React, { useEffect, Fragment } from 'react';

const Turf = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <Fragment>
            <main id="main">

                <section class="alt-services section-bg section-description">
                    <div class="container" data-aos="fade-up">

                        <div class="row justify-content-around">
                            <div class="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Turf Training</h3>
                                <h4>Unleash your strength on the Turf.</h4>

                                <p className="mt-4">Our strength and weightlifting classes are paired with bursts of cardio to sculpt, tone, and transform your body. Lifting weights isn’t just about building muscle—it’s a necessity for long-term health. Strength training improves bone density, boosts metabolism, and enhances everyday functional movement. Whether you're deadlifting or crushing kettlebell swings, this is where power meets purpose. Get ready to feel stronger, move better, and show up for yourself like never before.</p>
                            </div>

                            <div class="col-lg-6 img-bg" style={{ backgroundImage: `url("/assets/img/home/turf.jpg")` }} data-aos="zoom-in" data-aos-delay="100"></div>
                        </div>

                    </div>
                </section>
            </main>            
        </Fragment>
    )
}

export default Turf;