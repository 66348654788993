import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';

const Inicio = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <section id="hero" className="hero">
                <div className="info d-flex align-items-center">
                    <div className="container-xxl">
                        <div className="row mx-0 justify-content-center">
                            <div id="titulo_texto" className="col-lg-8 text-center">
                                <h2 data-aos="fade-down">WELCOME TO YOUR FITNESS COMMUNITY</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                    {/* <div className="carousel-item active" style={{ backgroundImage: `url("/assets/img/headers/home.jpg")` }}></div> */}
                    <div className="carousel-item active">
                        {/* Video en lugar de imagen de fondo */}
                        <video 
                            className="d-block w-100" 
                            autoPlay 
                            loop 
                            muted
                            style={{ objectFit: 'cover', height: '100vh' }} 
                        >
                            <source src="/assets/img/home/header.mp4" type="video/mp4" />
                            Tu navegador no soporta el elemento de video.
                        </video>
                    </div>
                </div>
            </section>

			<main id="main">
                <section className="section-bg">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="cuarto text-center">OUR COMMUNITY CELEBRATES EVERY JOURNEY</h2>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">ALL PEOPLE, ALL PATHS, ALL BODIES, ALL WELCOME.</p>
                            </div>

                            <div className="col-md-12 text-center">
                                <a className="btn btn-general btn-lg btn-light mx-3 my-2" href="https://clients.mindbodyonline.com/classic/ws?studioid=5740671&stype=40&prodid=103">JOIN NOW</a>
                                <a className="btn btn-general btn-lg btn-light mx-3 my-2" href="#personal_training">PERSONAL TRIANING</a>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg" id="the_fc_experience">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="cuarto text-center">THE FITNESS COLLECTIVE EXPERIENCE</h2>
                        </div>

                        <div className="row mx-0 gy-2">
                            <div className="col-lg-4 col-md-4 col-6 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <Link to="/turf">
                                    <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/turf.jpg")` }}>
                                        <div className="content-amenities">
                                            <h3 className="text-center">TURF</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-4 col-md-4 col-6 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <Link to="/the_bar_studio">
                                    <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/the_bar_studio.jpeg")` }}>
                                        <div className="content-amenities">
                                            <h3 className="text-center">THE BAR STUDIO</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-4 col-md-4 col-6 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <Link to="/lagree">
                                    <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/lagree.jpg")` }}>
                                        <div className="content-amenities">
                                            <h3 className="text-center">LAGREE</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-4 col-md-4 col-6 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <Link to="/red_room">
                                    <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/red_room.jpg")` }}>
                                        <div className="content-amenities">
                                            <h3 className="text-center">RED ROOM</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-4 col-md-4 col-6 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <Link to="/cold_plunge">
                                    <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/cold_plunge.jpeg")` }}>
                                        <div className="content-amenities">
                                            <h3 className="text-center">COLD PLUNGE</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-4 col-md-4 col-6 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <Link to="/open_gym">
                                    <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/open_gym.jpg")` }}>
                                        <div className="content-amenities">
                                            <h3 className="text-center">OPEN GYM</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg get-started services" id="memberships">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="row mx-0 gy-2">

                            <div className="col-lg-3 col-md-4 col-12" data-aos="fade-up" data-aos-delay="100">
                                <div className="section-header membership-header-container">
                                    <h2 className="terciario">Membership Plans</h2>
                                    <a className="btn btn-general btn-lg btn-dark mx-3 my-2" href="https://clients.mindbodyonline.com/classic/ws?studioid=5740671&stype=40&prodid=103">GET YOUR FREE TRIAL</a>
                                </div>
                            </div>

                            <div className="col-lg-9 col-md-8 col-12" data-aos="fade-up" data-aos-delay="100">
                                <div className="row container-membership container-membership-red mx-2">
                                    <div className="col-lg-6 col-md-6 col-12 text-center container-membership-1">
                                        <h6>ALL ACCESS</h6>
                                        <h5>$147/Month</h5>
                                        <p>Enjoy unlimited classes, recovery sessions, open gym, and turf access. Plus, unlock personal training options with our coaches or yours (fees apply)</p>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12 text-center container-membership-2">
                                        <h6>ALL ACCESS X2</h6>
                                        <h5>$217/month</h5>
                                        <p>Share this membership with a friend and unlock all the perks of All Access—double the freedom, double the experience!</p>
                                    </div>
                                </div>

                                <div className="row container-membership container-membership-red mx-2 mt-3 pt-3">
                                    <div className="col-lg-4 col-md-4 col-12 text-center">
                                        <h6>FLEX ACCESS</h6>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-12 text-center">
                                        <p>Unlimited Open Gym and recovery sessions. Personal coaching is available at Fitness Collective for an additional cost.</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

                <section className="section-bg" id="schedule">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="row mx-0 justify-content-around gy-2">
                            <div className="col-lg-12 d-flex flex-column">
                                <div className="section-header">
                                    <h2 className="cuarto text-center">CLASS SCHEDULE</h2>
                                </div>

                                <div className="mb-2 text-center" data-aos="fade-up" data-aos-delay="100">
                                    <p className="img-description">Please arrive 10 minutes early. Late arrivals will not be permitted once the class begins.</p>
                                </div>

                                <div className="mb-2 text-center" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-calendar-week icono-temporal"></i>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg" id="personal_training">
                    <div className="container-xxl" data-aos="fade-up">
                        <div className="row mx-0 gy-2">
                            <div className="col-lg-12 col-md-12 col-12 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/the_bar_studio.jpeg")` }}>
                                    <div className="content-amenities">
                                        <h3 className="text-center">PERSONAL TRAINING AT FITNESS COLLECTIVE</h3>
                                    </div>

                                    <div className="overlay">
                                        <h3 className="overlay-text text-center">Elevate your training with the freedom to work alongside your trainer—Train your way in a space that adapts to you. Whether you're a client or a trainer, our versatile facilities are designed to support every style of workout, offering top-tier equipment and a motivating environment for peak performance.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-bg" id="free_trial" style={{ backgroundImage: `url("/assets/img/home/free_trial.jpg")` }}>
                    <div className="container-xxl" data-aos="fade-up">
                        <div className="section-header">
                            <h2 className="cuarto text-center">TRY US OUT</h2>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">Start your journey with us today! Enjoy 3 free days at FC, spread out over a week from the moment you activate your trial.</p>
                            </div>

                            <div className="col-md-12 text-center">
                                <a className="btn btn-general btn-lg btn-light mx-3 my-2" href="https://clients.mindbodyonline.com/classic/ws?studioid=5740671&stype=40&prodid=103">START FREE TRIAL</a>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg" id="us">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="cuarto text-center">US</h2>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">At Fitness Collective, We’re a space where you feel supported, not judged, and where each workout becomes a celebration of what your body can do. We focus on building strength that goes beyond just the physical—it's about mental clarity, confidence, and real connection.</p>
                            </div>

                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">Here, you won't find intimidating fitness trends or endless diet fads. We’re all about building sustainable habits that make you feel good, day in and day out. Fitness isn’t about perfection—it’s about progress, consistency, and a community that has your back.</p>
                            </div>

                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">At Fitness Collective, you're not just another member. You’re a part of something bigger—a space where relationships extend beyond the studio and where self-care is a shared experience. This is a place where you can be your true self, with no pressure, just growth.</p>
                            </div>

                            <div className="col-md-12 text-center">
                                <a href="https://apps.apple.com/ma/app/fitness-collective/id6451108000" target="_blank" rel="noopener noreferrer">
                                    <img className="btn-store" src="/assets/img/home/appstore.png" alt="Download on the App Store" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=ie.zappy.fennec.eltmplo&hl=es_419" target="_blank" rel="noopener noreferrer">
                                    <img className="btn-store" src="/assets/img/home/playstore.png" alt="Get it on Google Play" />
                                </a>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg" id="location">
                    <div data-aos="fade-up">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d705.0592830478779!2d-117.2546639209791!3d32.79634070406046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dc011362d93411%3A0xf8ccc06b16800dd6!2sFitness%20Collective!5e0!3m2!1ses!2smx!4v1736523638980!5m2!1ses!2smx" style={{border: '0', width: '100%', height: '420px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </section>
            </main>            
	    </Fragment>
    )
}

export default Inicio;