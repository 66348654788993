import React, { useEffect, Fragment } from 'react';

const OpenGym = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <Fragment>
            <main id="main">

                <section class="alt-services section-bg section-description">
                    <div class="container" data-aos="fade-up">

                        <div class="row justify-content-around">
                            <div class="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Open Gym</h3>
                                <h4>Unlock your potential with Open Gym.</h4>

                                <p className="mt-4">Unlock your potential with Open Gym at Fitness Collective. Whether you’re looking to refine your routine, focus on specific goals, or just work at your own pace, our space is yours to shape.</p>
                            </div>

                            <div class="col-lg-6 img-bg" style={{ backgroundImage: `url("/assets/img/home/open_gym.jpg")` }} data-aos="zoom-in" data-aos-delay="100"></div>
                        </div>

                    </div>
                </section>
            </main>            
        </Fragment>
    )
}

export default OpenGym;